<template>
    <div class="box">
        <div class="box-header with-border">
            <h3>
                <previous-route-block/>

                {{ page_title }}
            </h3>
        </div>

        <div class="box-body">
            <vue-tabs>
                <v-tab :title="locale"
                       v-bind:key="index"
                       v-for="(locale, index) in localizations">
                    <vue-tabs @tab-change="handleTabChange">
                        <v-tab :title="`${section}_${locale}`"
                               class="nav-tab-content" style="padding: 10px;"
                               v-bind:key="index"
                               v-for="(section, index) in locale_sections_data">
                            <div class="form-group" v-for="(translation, key) in translations"
                                 v-if="translations">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label class="full-width" for="translation_item">
                                            <input :value="translation.item"
                                                   class="form-control"
                                                   disabled
                                                   id="translation_item"
                                                   name="translation_item"
                                                   style="margin-bottom: 10px;"
                                                   type="text">
                                        </label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="full-width" for="translation_id">
                                            <input class="form-control"
                                                   id="translation_id"
                                                   name="translation_id"
                                                   placeholder="Текст перевода"
                                                   style="margin-bottom: 10px;"
                                                   type="text"
                                                   v-model="translations[key].text">
                                        </label>
                                    </div>
                                    <div class="col-md-1">
                                        <button class="btn btn-success pull-right"
                                                style="min-width: 60px; min-height: 44px;"
                                                type="button"
                                                v-on:click="updateTranslate(translation.id, translations[key].text)">
                                            <i class="fa fa-save"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </v-tab>
                    </vue-tabs>
                </v-tab>
            </vue-tabs>
        </div>
    </div>
</template>

<script>
    import {VTab, VueTabs} from 'vue-nav-tabs';
    import 'vue-nav-tabs/themes/vue-tabs.css';

    export default {
        name: 'translations',

        components: {
            VueTabs,
            VTab,
        },

        data: () => ({
            page_title: 'Переводы',

            localizations: [],
            locale_sections_data: [],
            translations: null,
        }),

        created() {
            this.getTranslations();
        },

        methods: {
            getTranslations() {
                API.apiClient.get('/translations').then((response) => {
                    this.localizations = response.data.localizations;
                    this.locale_sections_data = response.data.locale_sections_data;
                }).catch((error) => {
                    errorHandler(error);
                });
            },

            handleTabChange(tabIndex, newTab) {
                API.apiClient.get('/translations/section/' + newTab.title).then((response) => {
                    this.translations = null;
                    this.translations = response.data.translations;
                }).catch((error) => {
                    errorHandler(error);
                });
            },

            updateTranslate(translate_id, translateText) {
                API.apiClient.put('/translations/edit', {
                    translate_id: parseInt(translate_id),
                    translate_text: translateText,
                }).then(() => {
                    showSuccess();
                }).catch((error) => {
                    errorHandler(error);
                });
            }
        },
    }
</script>

<style scoped>

</style>
